<template>
  <div class="sm:col-span-4 md:col-span-4 lg:col-span-3 xl:col-span-2">
    <div
      @click="togglesidebar(false)"
      v-bind:class="{ hidden: !sidebarOpen }"
      class="fixed bg-black opacity-40 h-screen w-screen z-20"
    ></div>
    <div
      v-bind:class="{ hidden: !sidebarOpen }"
      class="bg-white justify-center fixed top-0 left-0 z-30 h-screen sm:block sm:sticky sm:w-full"
    >
      <div class="py-1 flex flex-col justify-center items-center">
        <img
          class="object-contain h-24 w-full"
          src="/img/logo-kuydinas.png"
          alt="Logo KuyDinas"
        />
        <div class="bg-gray-400 rounded-lg h-0.5 w-52 mt-2"></div>
      </div>
      <ul>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <router-link
            to="/"
            class="flex items-center"
            :class="currentRouteName == '/' ? 'bg-orange p-1 rounded-lg' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span @click="togglesidebar(false)" class="ml-2">Beranda</span>
          </router-link>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <a
            href="https://kuydinas.id"
            class="flex items-center"
            :class="
              currentRouteName == '/materiskd' ? 'bg-orange p-1 rounded-lg' : ''
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 1696 1536"
            >
              <path
                fill="currentColor"
                d="M1671 350q40 57 18 129l-275 906q-19 64-76.5 107.5T1215 1536H292q-77 0-148.5-53.5T44 1351q-24-67-2-127q0-4 3-27t4-37q1-8-3-21.5t-3-19.5q2-11 8-21t16.5-23.5T84 1051q23-38 45-91.5t30-91.5q3-10 .5-30t-.5-28q3-11 17-28t17-23q21-36 42-92t25-90q1-9-2.5-32t.5-28q4-13 22-30.5t22-22.5q19-26 42.5-84.5T372 283q1-8-3-25.5t-2-26.5q2-8 9-18t18-23t17-21q8-12 16.5-30.5t15-35t16-36t19.5-32T504.5 12t36-11.5T588 6l-1 3q38-9 51-9h761q74 0 114 56t18 130l-274 906q-36 119-71.5 153.5T1057 1280H188q-27 0-38 15q-11 16-1 43q24 70 144 70h923q29 0 56-15.5t35-41.5l300-987q7-22 5-57q38 15 59 43m-1064 2q-4 13 2 22.5t20 9.5h608q13 0 25.5-9.5T1279 352l21-64q4-13-2-22.5t-20-9.5H670q-13 0-25.5 9.5T628 288zm-83 256q-4 13 2 22.5t20 9.5h608q13 0 25.5-9.5T1196 608l21-64q4-13-2-22.5t-20-9.5H587q-13 0-25.5 9.5T545 544z"
              />
            </svg>
            <span class="ml-2">Materi SKD</span>
          </a>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <router-link
            to="/promotryout"
            class="flex items-center"
            :class="
              currentRouteName == '/promotryout'
                ? 'bg-orange p-1 rounded-lg'
                : ''
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
              />
            </svg>
            <span @click="togglesidebar(false)" class="ml-2"
              >Promo Try Out</span
            >
          </router-link>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <router-link
            to="/pengerjaantryout/skd"
            class="flex items-center"
            :class="
              currentRouteName == '/pengerjaantryout'
                ? 'bg-orange p-1 rounded-lg'
                : ''
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <span @click="togglesidebar(false)" class="ml-2"
              >Pengerjaan Try Out SKD</span
            >
          </router-link>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <router-link
            to="/peringkat/skd"
            class="flex items-center"
            :class="
              currentRouteName == '/peringkat' ? 'bg-orange p-1 rounded-lg' : ''
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
              />
            </svg>
            <span @click="togglesidebar(false)" class="ml-2"
              >Peringkat SKD</span
            >
          </router-link>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <router-link
            to="/dompet"
            class="flex items-center"
            :class="
              currentRouteName == '/dompet' ? 'bg-orange p-1 rounded-lg' : ''
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                d="M21,6H5c-.859,0-1.672-.372-2.235-.999,.55-.614,1.349-1.001,2.235-1.001H23c.553,0,1-.448,1-1s-.447-1-1-1H5C2.239,2,0,4.239,0,7v10c0,2.761,2.239,5,5,5H21c1.657,0,3-1.343,3-3V9c0-1.657-1.343-3-3-3Zm1,13c0,.551-.448,1-1,1H5c-1.654,0-3-1.346-3-3V6.998c.854,.639,1.904,1.002,3,1.002H21c.552,0,1,.449,1,1v10Zm-2-5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"
              />
            </svg>
            <span @click="togglesidebar(false)" class="ml-2">Dompet</span>
          </router-link>
        </li>
        <li class="p-1 rounded-lg px-2 mx-2 mt-2 hover:bg-orangeHover">
          <button @click.prevent="logout()" class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                d="M2,21V3A1,1,0,0,1,3,2H8V0H3A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H8V22H3A1,1,0,0,1,2,21Z"
              />
              <path
                d="M23.123,9.879,18.537,5.293,17.123,6.707l4.264,4.264L5,11l0,2,16.443-.029-4.322,4.322,1.414,1.414,4.586-4.586A3,3,0,0,0,23.123,9.879Z"
              />
            </svg>
            <span class="ml-2">Keluar</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { googleLogout } from "vue3-google-login";

export default {
  props: ["sidebarOpen", "togglesidebar"],
  data() {
    return {
      showpengerjaan: false,
      showperingkat: false,
    };
  },

  computed: {
    currentRouteName() {
      return "/" + this.$router.currentRoute.value.path.split("/")[1];
    },
  },
  methods: {
    klikpengerjaan: function () {
      this.showpengerjaan = !this.showpengerjaan;
      this.showperingkat = false;
    },
    klikperingkat: function () {
      this.showperingkat = !this.showperingkat;
      this.showpengerjaan = false;
    },
    logout() {
      this.$swal({
        title: "Apakah kamu yakin ingin keluar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Ya, keluar",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          googleLogout();
          this.loggedIn = false;
          this.$emit("listenerChild", { stt: "loggedIn", loggedIn: false });
          localStorage.removeItem("loggedIn");
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      });
    },
  },
};
</script>
