<template>
  <ProfilLengkapi
    v-if="loggedIn && user.provinsi == '-'"
    :user="user"
  ></ProfilLengkapi>

  <DashboardUtama
    v-else-if="loggedIn && sedangdikerjakan == null"
    :user="user"
  ></DashboardUtama>

  <PengerjaanSKD
    v-else-if="loggedIn && sedangdikerjakan != null"
  ></PengerjaanSKD>
  <!-- <div v-else-if="loggedIn && sedangdikerjakan == '611a528428bd0'" :user="user">
    tes dulu
  </div> -->

  <AutentifikasiView v-else></AutentifikasiView>
</template>

<script>
import axios from "axios";

import DashboardUtama from "./views/komponenutama/DashboardUtama.vue";
import AutentifikasiView from "./views/autentifikasi/AutentifikasiView.vue";
import ProfilLengkapi from "./views/autentifikasi/ProfilLengkapi.vue";
import PengerjaanSKD from "./views/komponenutama/PengerjaanSKD.vue";

export default {
  components: {
    DashboardUtama,
    AutentifikasiView,
    ProfilLengkapi,
    PengerjaanSKD,
  },
  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      user: JSON.parse(localStorage.getItem("user")),
      sedangdikerjakan: localStorage.getItem("sedangdikerjakan"),
    };
  },
  mounted() {
    if (this.user != null) {
      axios
        .get(this.http + "/api/getinfoakun/" + this.user.email)
        .then((response) => {
          this.user = response.data;
          localStorage.setItem("user", JSON.stringify(this.user));
        });
    }
  },
};
</script>
