<template>
  <div class="sm:grid sm:grid-cols-12 sm:gap-x-2">
    <SidebarComponent
      :togglesidebar="getSidebar"
      :sidebarOpen="sidebarOpen"
    ></SidebarComponent>
    <div
      class="min-h-screen bg-gray-100 sm:col-span-8 md:col-span-8 lg:col-span-9 xl:col-span-10"
    >
      <HeaderComponent
        :togglesidebar="getSidebar"
        :user="user"
      ></HeaderComponent>
      <router-view :user="user"></router-view>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../../components/HeaderComponent.vue";
import SidebarComponent from "../../components/SidebarComponent.vue";
import FooterComponent from "../../components/FooterComponent.vue";

export default {
  components: {
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
  },
  props: {
    user: Object,
    listenerChild: Object,
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    getSidebar(sidebarOpen) {
      this.sidebarOpen = sidebarOpen;
    },
  },
};
</script>
