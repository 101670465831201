// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/profil/lengkapi",
    name: "lengkapiprofil",
    component: () => import("../views/autentifikasi/ProfilLengkapi.vue"),
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
  },
  {
    path: "/promotryout",
    name: "promotryout",
    component: () => import("../views/PromoTryOut.vue"),
  },
  {
    path: "/pengerjaantryout/skd",
    name: "pengerjaantryoutskd",
    component: () => import("../views/PengerjaanTryoutSKD.vue"),
  },
  // {
  //   path: "/pengerjaantryout/sedangdikerjakan",
  //   name: "pengerjaansedangdikerjakan",
  //   component: () => import("../views/komponenutama/PengerjaanSKD.vue"),
  // },
  {
    path: "/pengerjaantryouts",
    name: "pengerjaantryouts",
    component: () => import("../views/komponenutama/PengerjaanSKD.vue"),
  },
  {
    path: "/pengerjaantryout/persiapan/skd/:eid/:ulang",
    name: "pengerjaantryoutpersiapan",
    component: () => import("../views/PersiapanPengerjaanTryOutSKD.vue"),
    props: true,
  },
  {
    path: "/pengerjaantryout/persiapan/skd/:eid",
    name: "pengerjaantryoutpersiapanulang",
    component: () => import("../views/PersiapanPengerjaanTryOutSKD.vue"),
    props: true,
  },
  {
    path: "/pengerjaantryout/tampilkanhasil/skd/:eid",
    name: "tampilkanhasil",
    component: () => import("../views/TampilkanHasilSKD.vue"),
    props: true,
  },
  {
    path: "/peringkat/skd",
    name: "peringkatskd",
    component: () => import("../views/PeringkatSKD.vue"),
  },
  {
    path: "/dompet",
    name: "dompet",
    component: () => import("../views/DompetView.vue"),
  },
  {
    path: "/dompet/pembayaran/:snaptoken",
    name: "dompetpembayaran",
    component: () => import("../views/DompetPembayaran.vue"),
    props: true,
  },
  {
    path: "/promotryout/skd/:eid",
    name: "promotryoutskdlihat",
    component: () => import("../views/pembayaran/PembayaranSKD.vue"),
    props: true,
  },
  {
    path: "/promotryout/skd/uploadbukti/:eid",
    name: "uploadbukti",
    component: () => import("../views/pembayaran/UploadBuktiAkbar.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
