<template>
  <div class="flex flex-col h-screen">
    <div class="p-2 sm:p-4 bg-primarykuy">
      <img
        src="/img/logo-kuydinas.png"
        class="object-contain h-16 sm:h-20"
        alt=""
      />
    </div>
    <div class="bg-gray-100 flex flex-1 justify-center">
      <div class="grid justify-between">
        <div class="flex flex-col mb-3 items-center justify-center">
          <div class="bg-primaryLight p-3.5 px-14 rounded-3xl relative">
            <div class="text-xl font-bold">Masuk</div>
          </div>
          <div class="bg-white p-10 -mt-5 rounded-2xl w-full">
            <button class="bg-primaryLight p-4 rounded-3xl">
              <!-- Masuk menggunakan Gmail -->
              <GoogleLogin :callback="callback" />
            </button>
            <div class="flex justify-center mt-2">
              <span
                class="hover:text-primary transition duration-200 text-sm text-gray-500"
              >
                Masuk dengan akun Google
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full bg-primarykuy px-12 py-3 text-center text-white lg:text-base text-sm"
    >
      Hubungi Kami
      <div class="grid grid-cols-12">
        <div class="sm:col-span-4 col-span-12">
          <div
            href=""
            class="flex items-center gap-x-3 hover:text-primaryLight transition duration-200 md:justify-start justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="w-5 h-5"
              fill="currentColor"
            >
              <path
                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
              />
            </svg>
            kuydinas@gmail.com
          </div>
        </div>
        <div class="sm:col-span-4 col-span-12 flex justify-center">
          <a
            href="https://www.instagram.com/kuydinas.id/"
            class="flex items-center gap-x-3 hover:text-primaryLight transition duration-200 lg:mr-10 md:mr-8 mr-0 md:justify-start justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="w-5 h-5"
              fill="currentColor"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
            kuy_dinas
          </a>
        </div>
        <div class="sm:col-span-4 col-span-12 sm:flex sm:justify-end">
          <a
            href="https://api.whatsapp.com/send?phone=6285161696493"
            target="_blank"
            class="flex items-center gap-x-3 hover:text-primaryLight transition duration-200 md:justify-start justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="w-5 h-5"
              fill="currentColor"
            >
              <path
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
              />
            </svg>
            0851-6169-6493
          </a>
        </div>
      </div>
      <hr class="my-2" />
      <div class="text-sm">2021 - Kuy Dinas.</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { decodeCredential } from "vue3-google-login";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      user_email: localStorage.getItem("user_email"),
      callback: (response) => {
        this.loggedIn = true;
        this.user = decodeCredential(response.credential);
        this.cekAkun();
      },
    };
  },
  methods: {
    cekAkun() {
      axios
        .get(this.http + "/api/getinfoakun/" + this.user.email)
        .then((response) => {
          console.log(response.data);
          console.log("ada keanehan");
          if (response.data == "") {
            axios
              .post(this.http + "/api/tambahakun", {
                email: this.user.email,
                name: this.user.name,
                picture: this.user.picture,
              })
              .then((response) => {
                this.user = response.data;
                localStorage.setItem("user", JSON.stringify(this.user));
                localStorage.setItem("loggedIn", true);
                location.reload("/profil/edit");
              });
          } else {
            this.user = response.data;
            localStorage.setItem("user", JSON.stringify(this.user));
            localStorage.setItem("loggedIn", true);
            if (response.data.nowa == "-") {
              location.reload("/profil/edit");
            } else {
              location.reload("/");
            }
          }
        });
    },
  },
};
</script>
