<template>
  <div class="bg-primarykuy px-8 text-white text-xs sticky top-full">
    <div class="flex justify-between items-center h-10">
      <div>Kuy Dinas 2024</div>
      <a
        href="https://www.instagram.com/kuy_dinas"
        target="_blank"
        class="flex items-center"
      >
        <img src="/img/icon-instagrams.png" alt="" class="h-6" />
        <span class="ml-2">kuy_dinas</span>
      </a>
    </div>
  </div>
</template>
