<template>
  <!-- ini bar profil -->
  <div class="bg-primarykuy grid grid-cols-3 gap-1 z-10 p-4 justify-between">
    <div class="text-white flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 sm:invisible"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <h2 class="sm:invisible" @click="togglesidebar(sidebarOpen)">Menu</h2>
    </div>
    <div class="flex flex-col col-span-2">
      <div class="dropdown inline-b lock relative">
        <div class="flex items-center justify-end text-right">
          <div class="font-semibold text-gray-200 mx-2">
            {{ user.name }}
          </div>
          <img
            class="w-10 border-white border-2 rounded-full"
            :src="user.picture"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <router-link
          to="/dompet"
          class="flex justify-center bg-yellow-400 py-1 rounded-lg text-sm mt-1 w-28"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 inline"
            viewBox="0 0 24 24"
          >
            <path
              d="M16.5,0c-4.206,0-7.5,1.977-7.5,4.5v2.587c-.483-.057-.985-.087-1.5-.087C3.294,7,0,8.977,0,11.5v8c0,2.523,3.294,4.5,7.5,4.5,3.407,0,6.216-1.297,7.16-3.131,.598,.087,1.214,.131,1.84,.131,4.206,0,7.5-1.977,7.5-4.5V4.5c0-2.523-3.294-4.5-7.5-4.5Zm5.5,12.5c0,1.18-2.352,2.5-5.5,2.5-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911ZM2,14.589c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5s-5.5-1.32-5.5-2.5v-.911Zm20-6.089c0,1.18-2.352,2.5-5.5,2.5-.535,0-1.06-.038-1.566-.112-.193-.887-.8-1.684-1.706-2.323,.984,.28,2.092,.435,3.272,.435,2.194,0,4.14-.538,5.5-1.411v.911Zm-5.5-6.5c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5ZM7.5,9c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5Zm0,13c-3.148,0-5.5-1.32-5.5-2.5v-.911c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Zm9-3c-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Z"
            />
          </svg>
          <span class="mx-1 text-gray-800">{{ user.koin }}</span>
          <span>koin</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    togglesidebar: Function,
  },
  data() {
    return {
      sidebarOpen: true,
    };
  },
};
</script>
