import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vue3GoogleLogin from "vue3-google-login";

// production
const CLIENT_ID =
  "680808063415-hpjnhrefo7bgnc2qqjvvbdc1fd8j7ljn.apps.googleusercontent.com";

// development
// const CLIENT_ID =
//   "496074641389-eoemi6a9uu2ochbqq5dmbf7frufpjcaj.apps.googleusercontent.com";

const app = createApp(App)
  .use(vue3GoogleLogin, {
    clientId: CLIENT_ID,
  })
  .use(router);

app.use(VueSweetalert2);

app.config.globalProperties.http = "https://backend.kuydinas.id";

app.mount("#app");

// createApp(Appa).mount("#app");
