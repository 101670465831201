<template>
  <nav
    class="bg-primarykuy text-base w-full p-4 font-bold text-center text-white lg:flex lg:justify-between lg:items-center"
  >
    <p class="uppercase">{{ namasoal }}</p>
    <p>
      Sisa waktu pengerjaan:<span class="ml-2">{{ wmenit }}:{{ wdetik }}</span>
    </p>
  </nav>

  <div class="grid grid-cols-12 my-4 px-2 gap-4">
    <!-- Judul -->
    <div class="font-bold text-xl lg:col-span-12 col-span-6">
      Soal Nomor {{ nomorsoal }}
    </div>
    <button
      class="bg-orange p-2 rounded-xl lg:hidden col-span-6"
      @click.prevent="tampilkandaftarsoal()"
    >
      Daftar Soal
    </button>

    <div
      :class="istampildaftarsoal"
      class="lg:hidden bg-black bg-opacity-60 absolute inset-0 flex justify-center items-center"
    >
      <div class="p-4 rounded-xl bg-white w-10/12">
        <div class="flex justify-between items-center">
          <div class="text-xl font-bold text-gray-600">Daftar Soal</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="tutupdaftarsoal()"
            class="h-8 w-8 bg-red-400 rounded-sm"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div class="h-0.5 bg-gray-300"></div>
        <div
          class="overflow-y-scroll h-40 flex flex-wrap gap-2 justify-center text-center mt-3"
        >
          <button
            class="border-2 h-8 w-11"
            v-for="(soal, index) in daftarsoal"
            @click.prevent="tampilkan(soal.sn)"
            :class="soal.choose != '1' ? 'bg-success' : ''"
            :key="soal.id"
          >
            {{ index + 1 }}
          </button>
        </div>
        <div class="w-full mx-auto mt-12 sm:w-1/2">
          <button
            @click.prevent="akhiri()"
            class="bg-orange p-2 rounded-xl w-full"
          >
            Akhiri Try Out
          </button>
        </div>
      </div>
    </div>
    <!-- Soal -->
    <div class="flex flex-col rounded-xl col-span-12 lg:col-span-8 mb-32">
      <div class="bg-secondarykuy rounded-t-xl p-3.5">
        <span v-html="pertanyaan" class="text-base items-center"></span>
      </div>
      <div class="flex flex-col px-3 bg-white pb-4 rounded-b-xl">
        <div class="mt-2" v-if="daftarsoal == null">
          <i>Loading ...</i>
        </div>
        <div class="mt-1" v-for="i in dop[nomorsoal - 1][0].length" :key="i.id">
          <button
            @click.prevent="kuncijawaban(dop[nomorsoal - 1][0][i - 1].optionid)"
            class="w-full text-left p-1 px-2 hover:bg-primaryLightHover rounded-xl"
            :class="
              dop[nomorsoal - 1][0][i - 1].optionid == dua[nomorsoal - 1].choose
                ? 'bg-primaryLight'
                : ''
            "
          >
            <div class="flex flex-row items-center">
              <div class="mr-2">
                {{ String.fromCharCode(parseInt(i) + parseInt(64)) }}.
              </div>
              <div
                v-html="dop[nomorsoal - 1][0][i - 1].option"
                class="text-base"
              ></div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Daftar Soal -->
    <div class="hidden mb-36 p-4 rounded-xl bg-white lg:block lg:col-span-4">
      <div class="flex justify-center text-xl font-bold text-gray-600">
        Daftar Soal
      </div>
      <div class="h-0.5 bg-gray-300"></div>
      <div class="flex flex-wrap gap-2 justify-center text-center mt-3">
        <button
          class="border-2 h-8 w-10"
          v-for="soal in daftarsoal"
          @click.prevent="tampilkan(soal.sn)"
          :class="soal.choose != '1' ? 'bg-success' : ''"
          :key="soal.id"
        >
          {{ soal.sn }}
        </button>
      </div>
      <div class="w-full mx-auto my-4 sm:w-1/2">
        <button
          @click.prevent="akhiri()"
          class="bg-orange p-2 rounded-xl w-full"
        >
          Akhiri Try Out
        </button>
      </div>
    </div>

    <div class="hidden lg:flex">
      <div
        class="fixed bottom-0 left-0 w-full bg-primarykuy p-2 grid grid-cols-12 gap-2 text-white font-bold"
        :class="shownavigasi ? '' : 'hidden'"
      >
        <button
          class="bg-orange p-2 rounded-xl col-span-12 sm:col-span-6 lg:col-span-3"
          v-if="nomorsoal != 1"
          @click.prevent="sebelumnya(nomorsoal)"
        >
          Soal Sebelumnya
        </button>
        <button
          class="bg-red-400 p-2 rounded-xl col-span-12 sm:col-span-6 lg:col-span-3"
          @click.prevent="hapusjawaban()"
        >
          Hapus Jawaban
        </button>
        <button
          class="bg-orange p-2 rounded-xl col-span-12 sm:col-span-6 lg:col-span-3"
          v-if="nomorsoal != totalsoal"
          @click.prevent="selanjutnya(nomorsoal)"
        >
          Soal Selanjutnya
        </button>
      </div>
    </div>

    <div class="lg:hidden">
      <div
        class="flex flex-row justify-between fixed bottom-0 left-0 w-full bg-primarykuy p-2 text-white font-bold"
        :class="shownavigasi ? '' : 'hidden'"
      >
        <button
          class="bg-orange p-2 rounded-xl w-20 flex justify-center items-center"
          v-if="nomorsoal != 1"
          @click.prevent="sebelumnya(nomorsoal)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          class="bg-red-400 p-2 rounded-xl w-20 flex justify-center items-center mx-2"
          @click.prevent="hapusjawaban()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
        <button
          class="bg-orange p-2 rounded-xl w-20 flex justify-center items-center"
          v-if="nomorsoal != totalsoal"
          @click.prevent="selanjutnya(nomorsoal)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      email: JSON.parse(localStorage.getItem("user")).email,
      eid: localStorage.getItem("sedangdikerjakan"),
      wmenit: "-",
      wdetik: "-",
      countDown: 0,
      shownavigasi: true,
      namasoal: "",
      daftarsoal: null,
      totalsoal: "-",
      pertanyaan: "",
      dop: [[[]]],
      dua: [
        {
          choose: "",
        },
      ],
      nomorsoal: 1,
      istampildaftarsoal: "hidden",
      waktu_mulai: "-",
      waktu_pengerjaan: "-",
    };
  },
  mounted() {
    // this.$swal({
    //   title: "Error!",
    //   text: "Do you want to continue",
    //   icon: "error",
    //   confirmButtonText: "Cool",
    // });

    axios
      .get(
        this.http +
          "/api/getwaktudanbataspengerjaan/skd/" +
          this.email +
          "/" +
          this.eid
      )
      .then((response) => {
        this.waktu_mulai = response.data[0];
        this.waktu_pengerjaan = response.data[1];

        this.countDown =
          this.waktu_pengerjaan * 60 -
          (Math.floor(Date.now() / 1000) - this.waktu_mulai);
      });

    this.getremaining();
    axios
      .get(this.http + "/api/getnamasoal/skd/" + this.eid)
      .then((response) => {
        this.namasoal = response.data;
      });

    axios
      .get(this.http + "/api/getdaftarsoal/skd/" + this.email + "/" + this.eid)
      .then((response) => {
        this.totalsoal = response.data.length;
        this.daftarsoal = response.data;
        this.pertanyaan = response.data[0].qns;
        this.qid = response.data[0].qid;
      });
    axios
      .get(this.http + "/api/getdaftaropsi/skd/" + this.eid)
      .then((response) => {
        this.dop = response.data;
      });

    axios
      .get(this.http + "/api/getuser_answer/skd/" + this.email + "/" + this.eid)
      .then((response) => {
        this.dua = response.data;
      });

    setInterval(() => {
      if (this.countDown == 1) {
        this.countDown -= 2;
      } else if (this.countDown > 1) {
        this.countDown -= 1;
        this.wmenit = Math.round((this.countDown - 30) / 60);
        this.wdetik = this.countDown % 60;
        if (this.wdetik < 10) {
          this.wdetik = "0" + this.wdetik;
        }
      } else if (this.countDown < 0) {
        this.$swal({
          title: "Waktu Pengerjaan telah berakhir",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        setTimeout(() => {
          this.akhiripengerjaan();
        }, 3000);
      }
    }, 1000);
  },
  methods: {
    getremaining() {
      this.countDown =
        this.waktu_pengerjaan * 60 -
        (Math.floor(Date.now() / 1000) - this.waktu_mulai);
    },
    kuncijawaban(optionid) {
      this.shownavigasi = false;
      this.$swal({
        text: "Mengunci jawaban ...",
        showConfirmButton: false,
        timer: 500,
      });
      this.shownavigasi = true;
      let data = {
        email: this.email,
        eid: this.eid,
        qid: this.qid,
        optionid: optionid,
      };
      axios.post(this.http + "/api/kuncijawaban/skd", data).then((response) => {
        this.daftarsoal = response.data[0];
        this.dua = response.data[1];
      });
    },
    hapusjawaban() {
      this.$swal({
        text: "Menghapus jawaban ...",
        showConfirmButton: false,
        timer: 500,
      });
      if (this.opsiterjawab != "Tidak dijawab") {
        this.shownavigasi = false;
        let data = {
          email: this.email,
          eid: this.eid,
          qid: this.qid,
        };
        this.shownavigasi = true;
        axios
          .post(this.http + "/api/hapusjawaban/skd", data)
          .then((response) => {
            this.daftarsoal = response.data[0];
            this.dua = response.data[1];
          });
      }
    },
    tampilkan(sn) {
      this.nomorsoal = parseInt(sn);
      this.pertanyaan = this.daftarsoal[sn - 1].qns;
      this.qid = this.daftarsoal[sn - 1].qid;
      this.istampildaftarsoal = "hidden";
      this.getremaining();
    },
    selanjutnya(sn) {
      this.nomorsoal = parseInt(sn) + parseInt(1);
      this.pertanyaan = this.daftarsoal[sn].qns;
      this.qid = this.daftarsoal[sn].qid;
      this.getremaining();
    },
    sebelumnya(sn) {
      this.nomorsoal = parseInt(sn) - parseInt(1);
      this.pertanyaan = this.daftarsoal[sn - 2].qns;
      this.qid = this.daftarsoal[sn - 2].qid;
      this.getremaining();
    },
    tampilkandaftarsoal() {
      this.istampildaftarsoal = "";
    },
    tutupdaftarsoal() {
      this.istampildaftarsoal = "hidden";
    },
    akhiri() {
      this.$swal({
        title: "Yakin mengakhiri try out?",
        icon: "info",
        text: "Periksa kembali pengerjaan kamu",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yakin!",
        confirmButtonColor: "#fdb827",
        cancelButtonText: "Tidak!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.akhiripengerjaan();
        } else {
          this.$swal("Semangat", "Kerjakan dengan kemampuan maksimal");
        }
      });
    },
    akhiripengerjaan() {
      axios
        .get(
          this.http + "/api/akhiripengerjaan/skd/" + this.email + "/" + this.eid
        )
        .then((response) => {
          localStorage.removeItem("sedangdikerjakan");
          if (response.data == "berhasil") {
            window.location.href =
              "#/pengerjaantryout/tampilkanhasil/skd/" + this.eid;

            location.reload(
              "#/pengerjaantryout/tampilkanhasil/skd/" + this.eid
            );
          }
        });
    },
  },
};
</script>
